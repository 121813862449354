<template>
  <div class="form-group">
    <input :type="type ? type : 'text'"
           class="form-control"
           :placeholder="label"
           @input="handleInput"
           @keyup.enter="$emit('enter')"
           :readonly="readonly ? readonly : false"
           :value="value"/>
    <label class="form-label floating-label" :data-content="label">
      <span class="hidden--visually">{{ label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "InputGroup",
  data() {
    return {
      content: this.value
    }
  },
  props: {
    label: {},
    value: {},
    type: {},
    readonly: {}
  },
  mounted() {
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>
